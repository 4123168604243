.header{
  border-bottom: 3px double #ccc;
}
.footer{
  border-top: 3px double #ccc;
}
.list-post > h2 {
  font-size: 16px;
  font-weight: bold;
  padding: 8px 0;
}
.list-post ul {
  box-sizing: border-box;
  padding-left: 16px;
}
.list-post li {
  display: flex;
  align-items: center;
  padding: 8px 0;
}
.list-post li .date {
  color: #666666;
  font-size: 14px;
  flex-shrink: 0;
  margin-right: 10px;
}
.list-post li .title {
  width: 0;
  flex-grow: 1;
  padding: 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-post li .title a {
  font-size: 16px;
}
.list-post li .categories {
  flex-shrink: 0;
  padding: 3px 0;
  margin-left: 10px;
}
.list-post li .categories a {
  font-size: 14px;
  color: #666666;
  margin-left: 8px;
}
.list-category h2 {
  font-size: 16px;
  font-weight: bold;
  padding: 12px 0;
}
.list-category div {
  padding: 8px 0 8px 10px;
}
.list-category div a {
  margin: 4px 6px;
  white-space: nowrap;
  font-size: 16px;
  display: inline-block;
  border-bottom: 1px solid #333333;
}
.page-search #search-input {
  text-align: center;
  font-size: 14px;
  border-bottom: 2px solid #eaeaea;
  width: 100%;
  padding: 10px 0;
}
.page-search h1 {
  padding: 12px 0;
}
.page-search h1 span {
  font-size: 16px;
  font-weight: bold;
  vertical-align: middle;
}
.page-search h1 img {
  display: inline-block;
  width: 21px;
  margin-left: 8px;
  vertical-align: middle;
  opacity: 0;
}
.page-search .list-search li {
  list-style: disc;
  margin-left: 16px;
  margin-bottom: 20px;
}
.page-search .list-search a {
  display: block;
  border-bottom: 1px dashed #ccc;
}
.page-search .list-search .title {
  display: inline-block;
  word-break: break-all;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 1px;
  border-bottom: 1px solid #999;
}
.page-search .list-search .content {
  display: block;
  font-size: 14px;
  color: #5a5a5a;
  word-break: break-all;
  padding: 10px 0;
}
.page-search .list-search .hint {
  font-size: inherit;
  font-weight: bold;
  color: #ff7f7f;
}
@media screen and (max-width: 560px) {
  .list-post > h2 {
    padding: 10px 0;
  }
  .list-post li {
    padding: 6px 0;
  }
  .list-post li .title a {
    font-size: 14px;
  }
  .list-post .categories {
    display: none;
  }
  .list-category h2 {
    padding: 10px 0;
  }
  .list-category div a {
    font-size: 14px;
  }
}
